.question {
    background-color: $green-light;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__head {
        background-color: $white;
        padding: 10px;
        text-align: center;
        color: $blue-dark;
        font-family: $font-family-extra-bold;
        font-size: $font-size-question;
        margin-bottom: 0;
    }

    &__text {
        padding: 1.75rem 1rem 1rem 2rem;
        text-align: center;
        color: $blue-dark;
        font-family: $font-family-extra-bold;
        font-size: $font-size-question-mobile;
        line-height: $line-height-answer;
        z-index: 99;
        position: relative;
        margin: 0;

        &::before {
            background-color: $white;
            content: '';
            height: 40px;
            width: 40px;
            left: 0;
            position: absolute;
            top: 0;
        }

        &::after {
            border-color: $white;
            border-style: solid;
            content: '';
            left: 0;
            position: absolute;
            top: 0;
            border-width: 20px 0 0 20px;
            height: 60px;
            width: 60px;
        }

        @include media-breakpoint-up(md) {
            p {
                margin: 0;
            }
        }
    }

    .answers {
        padding: 0 3rem;
        flex-grow: 1;

        &__button {
            margin-bottom: 1.5rem;
            text-align: center;

            .button {
                padding: 0.7rem 2.5rem;
                width: 100%;
                font-size: $font-size-button-mobile;
                line-height: $line-height-fz;
            }
        }
    }

    &__img {
        text-align: center;

        img {
            height: auto;
            width: 100%;
            max-height: 300px;
            object-fit: cover;
            object-position: top;
        }
    }

    @include media-breakpoint-down(sm) {
        .answers {
            padding: 0 1rem;
        }
    }
}
