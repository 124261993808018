@font-face {
    font-family: $font-family-regular;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/minion-Pro/MinionPro-Regular.otf') format('opentype');
}

@font-face {
    font-family: $font-family-medium;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/marianne-Medium/Marianne-Medium.otf') format('opentype');
}

@font-face {
    font-family: $font-family-bold;
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/marianne-Bold/Marianne-Bold.otf') format('opentype');
}

@font-face {
    font-family: $font-family-extra-bold;
    font-style: bold;
    font-weight: 800;
    src: url('/assets/fonts/marianne-ExtraBold/Marianne-ExtraBold.otf') format('opentype');
}

* {
    box-sizing: border-box;
}

body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
        /* stylelint-disable */
        $default-bg: url('/assets/images/background.png');
        background: var(--background-image, $default-bg);
        background-repeat: repeat;
        background-size: contain;
        background-position: left;
        background-attachment: scroll;
        background-color: var(--background-color, #fff)
        /* stylelint-enable */
    }
}