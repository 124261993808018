.landing-screen {
    position: relative;
    text-align: center;
    padding-bottom: 3rem;

    .text {
        background-color: $green-light;
        margin-bottom: 3rem;
        padding-top: 20px;

        &__title {
            color: $green-dark;
            font-family: $font-family-extra-bold;
            /* stylelint-disable-next-line */
            font-size: 3rem;
            line-height: 1;
            margin: 0 auto;
            text-align: left;
            position: relative;
            z-index: 1;
            max-width: 375px;

            span {
                display: block;
            }
        }

        &__img-planet {
            padding: 0;
            width: auto;
            float: right;

        }

        &__img-hand {
            max-width: 110px;
        }

        &__description {
            color: $white;
            font-family: $font-family-bold;
            font-size: $font-size-landing-description;
            line-height: 1.15;
            margin: 20px auto;
            max-width: 500px;
            text-align: left;

            span {
                background-color: $green-dark;
                display: inline-block;
                padding: 5px 10px 0;
            }
        }

        &::before {
            background-color: $white;
            content: '';
            height: 60px;
            left: 0;
            top: 0;
            position: absolute;
            width: 60px;
        }

        &::after {
            border-color: $white;
            border-style: solid;
            border-width: 30px 0 0 30px;
            content: '';
            height: 90px;
            left: 0;
            position: absolute;
            top: 0;
            width: 90px;
        }
        @include media-breakpoint-down(sm) {
            /* stylelint-disable */
            &__title {
                font-size: 3rem;
                max-width: 520px;
                padding: 1.25rem 0 0 2rem;
            }

            &__img-planet {
                height: auto;
                max-width: 80px;
                margin-right: 30px;
            }
            &__img-hand {
                max-width: 60px;
                padding: 0.8rem 0 0.8rem;
            }
            &__description {
                font-size: $font-size-button;
                max-width: 320px;
            }
            &::before {
                height: 40px;
                width: 40px;
            }

            &::after {
                border-width: 20px 0 0 20px;
                height: 60px;
                width: 60px;
            }
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 1.5rem;

            &__title {
                font-size: 3rem;
            }

            &__img-planet {
                padding: 0;
                max-width: 80px;
                margin: 10px 50px 0;
            }

            &__img-hand {
                max-width: 60px;
                padding: 0 0 0.8rem;
            }

            &__description {
                font-size: $font-size-button;
                max-width: 365px;
            }

            &::before {
                height: 40px;
                width: 40px;
            }

            &::after {
                border-width: 20px 0 0 20px;
                height: 60px;
                width: 60px;
            }
            /* stylelint-enable */
        }
    }

    &__btn {
        .button {
            font-size: $font-size-question-mobile;
            padding: 0.8rem;
            width: 100%;

            @include media-breakpoint-down(lg) {
                padding: 0.8rem;
                font-size: $font-size-lg;
            }
        }
    }
}
