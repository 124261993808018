.button {
    background-color: $blue-dark;
    border-radius: $answer-button-border-radius;
    border-style: none;
    color: $white;
    font-family: $font-family-bold;
    font-size: $font-size-button;
    outline: none;
    padding: 0 40px;
    text-align: center;
    display: block;
    margin: 0 auto;
    transition: background-color 0.15s ease-in;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        color: $white;
    }

    &--hidden {
        display: none;
    }
}

.radio-button {
    display: none;

    &--green + label {
        background-color: $green-dark;
    }

    &--red + label {
        background-color: $red;

    }
}
