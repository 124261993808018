.result {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    text-align: center;

    &__count {
        background-color: $green-dark;
        color: $white;
        font-family: $font-family-bold;
        margin-bottom: 0;
        font-size: $font-size-result-mobile;
        line-height: 1.35;
    }
    &__content {
        background-color: $green-light;
        flex-grow: 1;
        padding: 3.25rem 1rem 0;
        position: relative;
        min-height: 380px;

        .result-text {
            font-size: $font-size-result-mobile;
            color: $green-dark;
            font-family: $font-family-extra-bold;

            img {
                margin-bottom: 1.25rem;
            }
        }

        .bordered-text {
            color: $blue-dark;
            font-family: $font-family-bold;
            display: inline;font-size: $font-size-button;
            border-bottom: 0.2rem solid $blue-dark;
            padding-bottom: 1rem;
        }

        .description {
            font-size: $font-size-lg;
            margin: 3rem auto;
            color: $green-dark;
            font-family: $font-family-bold;
        }

        .hand {
            position: absolute;
            top: -1.95rem;
            max-width: 90px;
            max-height: 120px;
            &--left {
                left: -0.9rem;
                transform: rotate(305deg);
            }
            &--right {
                right: -0.9rem;
                transform: rotate(55deg) scaleX(-1);
            }
        }

        .button {
            word-break: break-word;
            font-size: $font-size-button-mobile;
            padding: 10px 30px;
        }
    }
}
