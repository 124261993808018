.answer {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 2rem;

    &__head {
        background-color: $white;
        text-align: center;
        color: $blue-dark;
        font-family: $font-family-extra-bold;
        padding: 10px 30px;
        font-size: $font-size-question;
        margin-bottom: 0;
    }

    &__top-text {
        background-color: $green-dark;
        min-height: 50px;
        padding: 1.75rem 1rem;
        text-align: center;

        p {
            color: $white;
            font-family: $font-family-bold;
            font-size: $font-size-question-mobile;
            line-height: $line-height-answer;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            padding: 1.5rem 1rem;
        }
    }

    &__info {
        align-items: center;
        background: $green-light;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.75rem 1rem 0;
        flex-grow: 1;
        color: $green-dark;
        font-family: $font-family-medium;
        font-size: $font-size-button-mobile;
        line-height: $line-height-info;
        text-align: center;

        .mention {
            /* stylelint-disable */
            padding: 1.3rem 0;
            font-size: 0.65em;
            text-align: left;
            margin-bottom: 0;
            width: 100%;
            /* stylelint-enable */
        }

        img {
            max-width: 115px;
            padding-bottom: 10px;
        }
        ul {
            list-style-type: none;
            padding-left: 0.5rem;
            margin: 0;
            text-align: left;
        }
    }

    &__submit-btn {
        button {
            padding: 15px 30px;
        }
    }
}
