.form-quiz {
    background: $white;
    height: 100%;
    max-width: 750px;
    margin: -1px auto 0;
    padding: 0 1.25rem;
    section[class] {
        min-height: 650px;
    }

    @include media-breakpoint-up(md) {
        max-width: 768px;
        padding: 0 2.5rem;

        section[class] {
            min-height: 700px;
        }
    }
    @include media-breakpoint-up(lg) {
        max-width: 900px;

        section[class] {
            max-width: 500px;
            min-height: 700px;
            margin: 0 auto;
        }
    }
}