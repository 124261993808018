.header {
    background: $white;

    &__logo {
        max-width: 750px;
        display: flex;
        justify-content: flex-end;
        padding: 4.25rem 3.375rem 1.5rem;
        margin: 0 auto;

        .ademe,
        .france {
            display: none;
        }
        img {
            max-width: 60px;
        }
    }
    &--landing {
        .header__logo {
            padding: 60px 50px;
            justify-content: space-between;
        }
        .ademe,
        .france {
            display: flex;
            max-width: 156px;
        }
        .ademe {
            max-width: 112px;
        }
        .flag {
            display: none;
        }
    }

    &--hidden {
        display: none;
    }

    @include media-breakpoint-up(md) {
        .header__logo {
            max-width: 500px;
            padding: 10px 0;
        }
        .ademe {
            max-width: 70px;
        }
        .france {
            max-width: 100px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0 20px;
        .header__logo {
            padding: 10px 0;
        }
        .ademe {
            max-width: 70px;
        }
        .france {
            max-width: 100px;
        }
    }
}
            
